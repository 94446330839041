<template>
  <div>
    <z-header></z-header>
    <!-- 轮播图 -->
    <swiper class="swiper_banner min_width" :options="swiperOption">
      <swiper-slide
        v-for="(item, index) of bannerList"
        :key="index"
        @click="onBanner(item)"
      >
        <img :src="item.imgUrl" />
      </swiper-slide>
      <!-- 如果需要分页器 -->
      <div
        class="swiper-pagination banner-swiper-pagination"
        slot="pagination"
      ></div>
      <!-- 如果需要导航按钮 -->
      <div
        class="swiper-button-prev banner-swiper-button-prev"
        slot="button-prev"
      ></div>
      <div
        class="swiper-button-next banner-swiper-button-next"
        slot="button-next"
      ></div>
    </swiper>
    <div class="page_center service_box">
      <div class="page_title">软件开发服务 · SOFTWARE DEVELOPMENT SERVICE</div>
      <p>
        　　深圳市腾创互联有限公司多年来在互联网行业深耕，积累了丰富的开发项目经验，目前已自主研发电商，房产，交友，直播等系统，为企业提供专业的APP、小程序、公众号、PC网站定制开发服务，拥有超过50人以上的开发团队，截止目前已服务超过300家公司企业。
      </p>
      <div class="service_support_box">
        <div class="service_support_item">
          <img src="../assets/ic_system_a.png" alt="" />
          <span>IOS APP 开发</span>
        </div>
        <div class="service_support_item">
          <img src="../assets/ic_system_b.png" alt="" />
          <span>Android APP 开发</span>
        </div>
        <div class="service_support_item">
          <img src="../assets/ic_system_c.png" alt="" />
          <span>PC 开发</span>
        </div>
        <div class="service_support_item">
          <img src="../assets/ic_system_d.png" alt="" />
          <span>供应链平台开发</span>
        </div>
        <div class="service_support_item">
          <img src="../assets/ic_system_e.png" alt="" />
          <span>直播系统开发</span>
        </div>
        <div class="service_support_item">
          <img src="../assets/ic_system_f.png" alt="" />
          <span>小程序商城开发</span>
        </div>
      </div>
    </div>
    <div class="service_process_box content_center">
      <div
        class="bg_image image0"
        :class="{ active: processIndex == 0, bgActive: lastProcessIndex == 0 }"
      ></div>
      <div
        class="bg_image image1"
        :class="{ active: processIndex == 1, bgActive: lastProcessIndex == 1 }"
      ></div>
      <div
        class="bg_image image2"
        :class="{ active: processIndex == 2, bgActive: lastProcessIndex == 2 }"
      ></div>
      <div
        class="bg_image image3"
        :class="{ active: processIndex == 3, bgActive: lastProcessIndex == 3 }"
      ></div>
      <div
        class="bg_image image4"
        :class="{ active: processIndex == 4, bgActive: lastProcessIndex == 4 }"
      ></div>
      <div
        class="bg_image image5"
        :class="{ active: processIndex == 5, bgActive: lastProcessIndex == 5 }"
      ></div>
      <div class="mask">
        <div class="page_title white">服务流程 · SEVICE PROCESS</div>
        <div class="page_center">
          <div class="service_list" @mouseover="onMouse(0)">
            <div class="icon">
              <img src="./../assets/ic_serviceflow_a.png" alt="需求沟通" />
            </div>
            <div class="name">需求沟通</div>
            <p>
              倾听客户需求，了解用户使用环境和操作流程，与客户签订合同(项目周期、项目价格、保密协议）
            </p>
          </div>
          <div class="service_list" @mouseover="onMouse(1)">
            <div class="icon">
              <img src="./../assets/ic_serviceflow_b.png" alt="交互设计" />
            </div>
            <div class="name">交互设计</div>
            <p>
              头脑风暴交互情景模拟原型设计，制作产品原型图与客户确认
              由项目经理全程参与，把控进度
            </p>
          </div>
          <div class="service_list" @mouseover="onMouse(2)">
            <div class="icon">
              <img src="./../assets/ic_serviceflow_c.png" alt="视觉UI设计" />
            </div>
            <div class="name">视觉UI设计</div>
            <p>
              色彩及平面元素设定， 结构和布局规范，设计高保真视
              觉图与客户确认，由产品经理把控进度确认
            </p>
          </div>
          <div class="service_list" @mouseover="onMouse(3)">
            <div class="icon">
              <img src="./../assets/ic_serviceflow_d.png" alt="需求沟通" />
            </div>
            <div class="name">系统开发</div>
            <p>
              实现页面移动应用数据对接，项目研发，接收测试工程师
              反馈后进行调整，由项目经理全程参与把控进度
            </p>
          </div>
          <div class="service_list" @mouseover="onMouse(4)">
            <div class="icon">
              <img src="./../assets/ic_serviceflow_e.png" alt="测试验收" />
            </div>
            <div class="name">测试验收</div>
            <p>
              修改调整，规范完善，项目测试，对照签约开发功能，验收功能成果，调整及优化，打造完美系统
            </p>
          </div>
          <div class="service_list" @mouseover="onMouse(5)">
            <div class="icon">
              <img src="./../assets/ic_serviceflow_f.png" alt="需求沟通" />
            </div>
            <div class="name">上线运营</div>
            <p>
              提供最终产品
              包给客户验收，验收合格，运营上线，系统享免费维护一年，二期合同价格优惠
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="case_box">
      <div class="page_center">
        <div class="case_title">成功案例 · SUCCESS CASE</div>
        <div class="case_info_box">
          <p>
            　　深圳市腾创互联科技有限公司拥有50人以上的APP开发/网站设计团队，提供多个平台开发服务，开发案例超过300个，我们了解大中小型企业的生意模式以及需求，倡导让客户感知产品的应用体验价值，公司目前开发的系统有“新零售Java商城平台系统、微分销系统、社交电商系统、知识付费系统、智慧餐厅系统、O2O同城商圈系统”。坚持以“APP公众号小程序定制开发、WEB网站开发、移动应用开发”为研究发展方向，以“客户需求”为企业核心动力，致力于为企业提供完整的移动互联网应用解决方案。
          </p>
          <div class="tab_box">
            <span
              :class="{ active: item.objId == devTypeId }"
              v-for="(item, index) in devTypeList"
              :key="index"
              @click="onCaseType(item)"
              >{{ item.name }}</span
            >
          </div>
        </div>
        <div class="swiper_case_box">
          <swiper class="swiper_case" :options="caseSwiperOption">
            <swiper-slide v-for="(item, index) of caseList" :key="index">
              <router-link :to="'/caseDetails/' + item.objId">
                <img class="swiper_case_img" :src="item.headImg" />
              </router-link>
            </swiper-slide>
          </swiper>
          <!-- 如果需要导航按钮 -->
          <div class="case-swiper-button-prev"></div>
          <div class="case-swiper-button-next"></div>
        </div>
      </div>
    </div>
    <div class="program_box">
      <div class="page_center">
        <div class="page_title">行业方案 · INDUSTRY SOLUTIONS</div>
        <div class="description">
          多年互联网开发与行业经验的沉淀，已覆盖二十余种主流行业，对焦目前主流的、热门的行业，为企业商家打造符合业务发展需要的APP系统开发，完备的电商系统开发解决方案服务
        </div>
        <div class="program_content_box">
          <img class="left_img" src="../assets/ic_decoration.png" alt="" />
          <div class="program_item right_arrow">
            <img src="../assets/img_program_b.png" alt="" />
          </div>
          <div class="program_item">
            <span>电商</span>
            <p>企业通过建立自营线上商城，通过会员、商品、订单、财务、和仓储等管理系统对线下线上多渠道业务进行统一处理。平台商城则可以多样化商品和服务类，整合行业资源，收取商品营销推广费用、类目使用费用和平台佣金等收入。</p>
          </div>
          <div class="program_item right_arrow">
            <img src="../assets/img_program_a.png" alt="" />
          </div>
          <div class="program_item">
            <span>同城</span>
            <p>
              商家即可以通过线上店铺在线销售，也可以通过我们线下的进销存和收银平台线下销售，线上线下数据完全融合
            </p>
          </div>
          <div class="program_item">
            <span>社交</span>
            <p>
              通过社交服务平台与朋友保持了更加直接的联系，建立大交际圈，满足用户的交流需求
            </p>
          </div>
          <div class="program_item left_arrow">
            <img src="../assets/img_program_c.png" alt="" />
          </div>
          <div class="program_item">
            <span>直播</span>
            <p>全面直播功能，APP/H5/小程序多端流量加持、搭建您的专属直播平台</p>
          </div>
          <div class="program_item left_arrow">
            <img src="../assets/img_program_d.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="service_data_box">
      <div class="page_title black">关于腾创 · ABOUT TENGCHUANG</div>
      <div class="page_center">
        <p class="directions">
          　　公司自成立以来，一直专注于软件系统研发，我们公司的理念是“技术为本，服务为先”，使命是“让每一个企业，都拥有自己的移动商业系统”。专注于移动互联网，坚持以技术创新为核心，以用户需求为导向，关注用户体验，市场发展方向，努力成为国内领先的移动互联网应用开发服务商，为企业客户提供最专业的移动应用产品的开发支持和解决方案。
        </p>
        <div class="data_list_box">
          <div class="data_list">
            <div class="info">
              <span>服务</span>
              <p>超过6种主流开发服务</p>
            </div>
            <div class="num"><number-grow :value="6"></number-grow>+</div>
          </div>
          <div class="data_list">
            <div class="info">
              <span>团队</span>
              <p>超过50人的技术开发团队</p>
            </div>
            <div class="num"><number-grow :value="50"></number-grow>+</div>
          </div>
          <div class="data_list">
            <div class="info">
              <span>企业</span>
              <p>已服务超过300家企业</p>
            </div>
            <div class="num"><number-grow :value="300"></number-grow>+</div>
          </div>
          <div class="data_list">
            <div class="info">
              <span>产品</span>
              <p>已设计超过10万张的产品</p>
            </div>
            <div class="num"><number-grow :value="10"></number-grow>万+</div>
          </div>
        </div>
      </div>
    </div>
    <div class="news_box content_center">
      <div class="page_title white">行业资讯 · INDUSTRY INFORMATION</div>
      <div class="page_center">
        <div class="news_list_box">
          <div class="news_type">APP开发资讯</div>
          <router-link
            v-for="(item, index) of developmentNewsList"
            :key="index"
            class="news_list"
            :class="{ first: index == 0 }"
            :to="'/newsDetails/' + item.objId + '.html'"
          >
            <div v-if="index == 0" class="image">
              <img :src="item.headImg" :alt="item.title" />
            </div>
            <div class="info">
              <div class="news_title">{{ item.title }}</div>
              <div v-if="index == 0" class="description">
                {{ item.introduce }}
              </div>
              <div class="time">{{ item.createTime }}</div>
            </div>
          </router-link>
        </div>
        <div class="news_list_box">
          <div class="news_type">电商运营资讯</div>
          <router-link
            v-for="(item, index) of operationNewsList"
            :key="index"
            class="news_list"
            :class="{ first: index == 0 }"
            :to="'/newsDetails/' + item.objId + '.html'"
          >
            <div v-if="index == 0" class="image">
              <img :src="item.headImg" :alt="item.title" />
            </div>
            <div class="info">
              <div class="news_title">{{ item.title }}</div>
              <div v-if="index == 0" class="description">
                {{ item.introduce }}
              </div>
              <div class="time">{{ item.createTime }}</div>
            </div>
          </router-link>
        </div>
        <div class="news_list_box">
          <div class="news_type">APP运营技巧</div>
          <router-link
            v-for="(item, index) of skillNewsList"
            :key="index"
            class="news_list"
            :class="{ first: index == 0 }"
            :to="'/newsDetails/' + item.objId + '.html'"
          >
            <div v-if="index == 0" class="image">
              <img :src="item.headImg" :alt="item.title" />
            </div>
            <div class="info">
              <div class="news_title">{{ item.title }}</div>
              <div v-if="index == 0" class="description">
                {{ item.introduce }}
              </div>
              <div class="time">{{ item.createTime }}</div>
            </div>
          </router-link>
        </div>
      </div>
      <router-link tag="div" class="btn_box" to="/skill">
        <button>更多资讯</button>
      </router-link>
    </div>
    <div class="partner_box">
      <div class="page_center">
        <div class="page_title black">合作伙伴 · PARTNER</div>
        <div class="partner_content">
          <a v-for="(item, index) of partnerList" :key="index" target="_blank">
            <img :src="item.imgUrl" :alt="item.name" />
          </a>
        </div>
      </div>
    </div>
    <div class="problem_box">
      <div class="page_title black">软件开发常见问题 · COMMON PROBLEM</div>
      <div class="page_center">
        <swiper v-if="problemSwiperAct" class="problem_content_box" ref="problemSwipper" :options="problemSwiperOption" >
          <swiper-slide v-for="(item, index) in problemList" :key="index">
            <router-link
              class="problem_list"
              :to="'/newsDetails/' + item.objId + '.html'"
            >
              <div class="problem_title">{{ item.title }}</div>
              <!-- <div class="problem_answer">{{ item.introduce }}</div> -->
            </router-link>
          </swiper-slide>
        </swiper>
        <div v-else class="problem_content_box"></div>
        <img class="image" src="./../assets/img_appfaq.png" />
      </div>
    </div>
    <z-footer></z-footer>
  </div>
</template>
<script>
import numberGrow from "@/components/common/number-grow-warp.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { mapState } from "vuex";

export default {
  name: "Home",
  components: {
    swiper,
    swiperSlide,
    numberGrow,
  },
  data() {
    return {
      swiperOption: {
        //自由轮播
        autoplay: {
          disableOnInteraction: false,
          delay: 3000,
        },
        //阻止冒泡
        preventLinksPropagation: false,
        // 划过的时间
        speed: 600,
        // 滑动衔接
        loop: false,
        // //手指
        // grabCursor: true,
        //flex布局
        setWrapperSize: true,
        //提前初始化
        notNextTick: true,
        pagination: {
          el: ".banner-swiper-pagination",
          clickable: true,
        },
        //设置上一页，下一页
        navigation: {
          nextEl: ".banner-swiper-button-next",
          prevEl: ".banner-swiper-button-prev",
        },
      },
      caseSwiperOption: {
        slidesPerView: 3,
        // centeredSlides: true,
        spaceBetween: 20,
        navigation: {
          nextEl: ".case-swiper-button-next",
          prevEl: ".case-swiper-button-prev",
        },
      },
      problemSwiperOption: {
        // 自由轮播
        autoplay: {
          disableOnInteraction: false,
          delay: 1200,
        },
        // 滑动衔接
        loop: true,
        slidesPerView: 6,
        spaceBetween: 0,
        direction: "vertical",
      },
      problemSwiperAct: false,
      bannerList: [],
      lastProcessIndex: 0,
      processIndex: 0,
      caseList: [],
      devTypeList: [
        {
          objId: "",
          name: "全部",
        },
      ],
      devTypeId: "",
      developmentNewsList: [],
      operationNewsList: [],
      skillNewsList: [],
      partnerList: [],
      problemList: [],
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    this.pageData();
    this.getCaseList();
  },
  mounted() {},
  methods: {
    onPageJump(url) {
      this.$router.push(url);
    },
    onMouse(index) {
      if (this.processIndex !== index) {
        this.lastProcessIndex = this.processIndex;
        this.processIndex = index;
      }
    },
    pageData() {
      // 轮播图
      this.$http.get("api/lunbo/v1/list?locatType=1101").then((res) => {
        this.bannerList = res.data;
      });
      // 案例分类
      this.$http.get("api/base_type/classify").then((res) => {
        this.devTypeList = this.devTypeList.concat(res.data.devType);
      });
      // 新闻
      this.$http
        .post("api/article/v1/list", {
          pageNo: 1,
          pageSize: 5,
          type: 3003,
        })
        .then((res) => {
          this.developmentNewsList = res.data.data;
        });
      this.$http
        .post("api/article/v1/list", {
          pageNo: 1,
          pageSize: 5,
          type: 3004,
        })
        .then((res) => {
          this.operationNewsList = res.data.data;
        });
      this.$http
        .post("api/article/v1/list", {
          pageNo: 1,
          pageSize: 5,
          type: 3005,
        })
        .then((res) => {
          this.skillNewsList = res.data.data;
        });

      // 合作伙伴
      this.$http.get("api/partner/v1/list").then((res) => {
        this.partnerList = res.data;
      });
      // 常见问题
      this.$http
        .post("api/article/v1/list", {
          pageNo: 1,
          pageSize: 10,
          type: 3006,
        })
        .then((res) => {
          this.problemList = res.data.data;
          this.problemSwiperAct = true
        });
    },
    onCaseType(item) {
      this.devTypeId = item.objId;
      this.getCaseList();
    },
    getCaseList() {
      // 案例
      let httpData = {
        pageNo: 1,
        pageSize: 12,
      };
      if (this.devTypeId) {
        httpData.devType = this.devTypeId;
      }
      this.$http.post("api/case/case_main", httpData).then((res) => {
        this.caseList = res.data.data;
      });
    },
    onBanner(item) {
      if (item.jumpRecord && item.jumpRecord.id) {
        this.$router.push("/goodDetails?id=" + item.jumpRecord.id);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.problem_box {
  background-color: #f7f7f7;
  padding: 80px 0;
  .page_center {
    margin-top: 65px;
    display: flex;
    justify-content: space-between;
    .problem_content_box {
      width: 500px;
      height: 390px;
      margin-top: 30px;
      .problem_list {
        display: block;
        margin-bottom: 32px;
        cursor: pointer;
        .problem_title {
          display: flex;
          align-items: center;
          font-size: 18px;
          color: #333333;
          &:hover {
            color: #000;
          }
          &::before {
            content: "";
            width: 30px;
            height: 30px;
            margin-right: 10px;
            background-image: url("./../assets/ic_appfaq_problem.png");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            flex-shrink: 0;
          }
        }
      }
    }
    .image {
      width: 514px;
      height: 413px;
    }
  }
}
.partner_box {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 80px 0;
  .page_center {
    .img_box {
      margin-top: 50px;
      img {
        width: 778px;
        height: 445px;
      }
    }
    .partner_title {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      p {
        font-size: 36px;
        color: #333333;
      }
      span {
        font-size: 24px;
        letter-spacing: 15px;
        color: #999999;
        margin-top: 20px;
      }
    }
    .partner_content {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;

      img {
        width: 190px;
        height: 88px;
        margin: 5px;
      }
    }
  }
}
.news_box {
  background-color: #2a2d34;
  padding: 80px 0;
  .page_center {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    .news_list_box {
      width: 370px;
      .news_type {
        font-size: 24px;
        color: #ffffff;
        margin-bottom: 25px;
      }
      .news_list {
        display: flex;
        padding: 10px 0;
        cursor: pointer;
        &.first {
          padding-bottom: 45px;
          .image {
            display: flex;
          }
          .info {
            flex-direction: column;
            .news_title {
              width: 180px;
            }
            .description {
              display: flex;
              @include bov(4);
            }
            .time {
              text-align: right;
            }
          }
        }
        .image {
          width: 180px;
          height: 120px;
          flex-shrink: 0;
          margin-right: 10px;
          display: none;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          flex: 1;
          display: flex;
          justify-content: space-between;
          .news_title {
            font-size: 14px;
            color: #b5bcd9;
            @include toe();
            width: 260px;
            &:hover {
              color: #e0e6ff;
            }
          }
          .description {
            display: none;
            font-size: 12px;
            color: #b5bcd9;
            opacity: 0.4;
            width: 180px;
            line-height: 150%;
          }
          .time {
            font-size: 14px;
            color: #b5bcd9;
            opacity: 0.2;
          }
        }
      }
    }
  }

  .btn_box {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    button {
      width: 240px;
      height: 43px;
      border: solid 1px #ffffff;
      font-size: 14px;
      color: #ffffff;
      background-color: transparent;
    }
  }
}
.service_data_box {
  background-color: #ffffff;
  padding: 80px 0;
  .page_center {
    // display: flex;
    // justify-content: center;
    .directions {
      margin: 30px auto 0 auto;
      width: 992px;
      font-size: 14px;
      color: #333333;
      line-height: 150%;
      text-align: center;
    }
    .data_list_box {
      margin-top: 60px;
      display: flex;
      justify-content: center;
      .data_list {
        width: 290px;
        height: 200px;
        display: flex;
        margin: 0 5px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: transform 0.4s;
        cursor: pointer;
        &:hover {
          transform: translateY(-10px);
        }
        &:nth-child(1) {
          background-image: url("../assets/bg_about_a.png");
        }
        &:nth-child(2) {
          background-image: url("../assets/bg_about_b.png");
        }
        &:nth-child(3) {
          background-image: url("../assets/bg_about_c.png");
        }
        &:nth-child(4) {
          background-image: url("../assets/bg_about_d.png");
        }
        span {
          font-size: 24px;
          color: #ffffff;
        }
        p {
          font-size: 14px;
          color: #ffffff;
          margin-top: 12px;
        }
        .num {
          font-size: 32px;
          color: #ffffff;
          span {
            font-size: inherit;
          }
        }
      }
    }
  }
}
.program_box {
  background-color: #fbf9ff;
  padding: 80px 0;
  .page_center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .description {
    width: 574px;
    font-size: 14px;
    line-height: 24px;
    color: #333333;
    margin-top: 60px;
  }
  .program_content_box {
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 1200px;
    .left_img {
      width: 90px;
      height: 600px;
      position: absolute;
      left: -90px;
      top: 0;
    }
    .program_item {
      width: 300px;
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      &.left_arrow::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: 20px solid #fff;
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
      }
      &.right_arrow::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-right: 20px solid #fff;
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
      }
      img {
        width: 100%;
        height: 100%;
      }
      span {
        font-size: 20px;
        color: #000000;
      }
      p {
        width: 200px;
        font-size: 14px;
        line-height: 21px;
        color: #000000;
        margin-top: 24px;
      }
    }
  }
}
.case_box {
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
  .case_title {
    font-size: 32px;
    color: #333333;
  }
  .case_info_box {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    p {
      width: 600px;
      font-size: 14px;
      line-height: 24px;
      color: #333333;
    }
    .tab_box {
      display: flex;
      align-items: center;
      span {
        height: 32px;
        font-size: 14px;
        color: #333333;
        line-height: 32px;
        margin-right: 30px;
        padding: 0 12px;
        cursor: pointer;
        &.active {
          background-color: #222222;
          color: #fff;
        }
      }
    }
  }
  .swiper_case_box {
    margin-top: 50px;
    height: 264px;
    position: relative;
    .swiper_case {
      padding-top: 10px;
    }

    .swiper_case_img {
      width: 100%;
      height: 264px;
      border-radius: 20px;
      cursor: pointer;
      transition: transform 0.4s;
      &:hover {
        box-shadow: 0px 1em 2em 0px rgba(48, 55, 66, 0.15);
        transform: translate(0, -10px);
      }
    }
    .case-swiper-button-prev {
      position: absolute;
      left: -100px;
      top: 50%;
      margin-top: -20px;
      width: 40px;
      height: 40px;
      background-image: url("../assets/ic_left.png");
      background-position: center center;
      background-size: 12px 20px;
      background-repeat: no-repeat;
      background-color: #222222;
      border-radius: 50%;
    }
    .case-swiper-button-next {
      position: absolute;
      right: -100px;
      top: 50%;
      margin-top: -20px;
      width: 40px;
      height: 40px;
      background-image: url("../assets/ic_right.png");
      background-position: center center;
      background-size: 12px 20px;
      background-repeat: no-repeat;
      background-color: #222222;
      border-radius: 50%;
    }
  }
}
.swiper_banner {
  height: 580px;
  width: 100%;
  img {
    height: 580px;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
    background-position: center center;
    background-repeat: no-repeat;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    content: "";
  }

  .swiper-button-next {
    background-image: url("../assets/ic_right.png");
    background-size: 12px 20px;
    right: 50px;
  }

  .swiper-button-prev {
    background-image: url("../assets/ic_left.png");
    background-size: 12px 20px;
    left: 50px;
  }

  .swiper-pagination {
    ::v-deep span {
      width: rem(80);
      background-color: #fff;
      border-radius: 0;
      height: rem(6);
    }
  }
}
.page_title {
  font-size: 32px;
  color: #333333;
  text-align: center;
  &.white {
    color: #fff;
  }
}
.service_box {
  height: 553px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;

  p {
    margin-top: 60px;
    font-size: 14px;
    color: #333333;
    width: 854px;
    text-align: center;
    line-height: 28px;
  }
  .service_support_box {
    margin-top: 100px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    .service_support_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 60px;
        height: 60px;
      }
      span {
        font-size: 14px;
        color: #333333;
        margin-top: 24px;
      }
    }
  }
}
@keyframes bgAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.service_process_box {
  position: relative;
  height: 700px;
  // min-width: 1200px;
  .bg_image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 700px;
    &.active {
      z-index: 3;
      animation-name: bgAnimation;
      animation-duration: 1s;
      animation-direction: alternate;
    }
    &.bgActive {
      z-index: 2;
    }
  }
  .image0 {
    background-image: url("https://qn.kemean.cn/upload/202007/06/1587634360038p0h4sn3r.png");
  }
  .image1 {
    background-image: url("https://qn.kemean.cn/upload/202007/06/1587634414093ks0luq2v.png");
  }
  .image2 {
    background-image: url("https://qn.kemean.cn/upload/202007/06/1587634424642714amfas.png");
  }
  .image3 {
    background-image: url("https://qn.kemean.cn/upload/202007/06/1587634435435s355b4l0.png");
  }
  .image4 {
    background-image: url("https://qn.kemean.cn/upload/202007/06/1587634445540dmomanhx.png");
  }
  .image5 {
    background-image: url("https://qn.kemean.cn/upload/202007/06/1587634455723ul1lbeto.png");
  }
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 700px;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .page_center {
      margin-top: 80px;
      display: flex;
      justify-content: center;
      .service_list {
        width: 210px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        &:hover .icon {
          transform: rotate(360deg);
        }
        .icon {
          width: 140px;
          height: 140px;
          transition: all 0.4s;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          margin-top: 24px;
          font-size: 20px;
          color: #ffffff;
        }
        p {
          font-size: 14px;
          color: #ffffff;
          margin-top: 30px;
          width: 140px;
          text-indent: 2em;
        }
      }
    }
  }
}
</style>
