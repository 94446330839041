<template>
  <span ref="numberGrow" :data-time="time" class="number-grow" :data-value="value">0</span>
</template>

<script>
export default {
  props: {
    time: {
      type: Number,
      default: 2
    },
    value: {
      type: Number,
      default: 720000
    }
  },
  data() {
    return {
      animation: true,
      func: null,
    }
  },
  methods: {
    numberGrow(ele) {
      let _this = this
      let step = (_this.value * 10) / (_this.time * 1000)
      let current = 0
      let start = 0
      let t = setInterval(function () {
        start += step
        if (start > _this.value) {
          clearInterval(t)
          start = _this.value
          t = null
        }
        if (current === start) {
          return
        }
        current = parseInt(start)
        ele.innerHTML = current.toString().replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, '$1,')
      }, 10)
    },
    scrollFnt() {
      if (this.animation) {
        let numberGrow = this.$refs.numberGrow;
        let clientHeight = document.body.clientHeight;
        let top = numberGrow.offsetTop - clientHeight;
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop >= top) {
          this.numberGrow(numberGrow);
          this.animation = false;
        }
      }
    }
  },
  mounted() {
    this.scrollFnt();
    // document.addEventListener("scroll", () => {
    //   this.scrollFnt();
    // });
    this.func = () => {
      this.scrollFnt();
    }
    document.addEventListener("scroll", this.func);
  },
  destroyed(){
    document.removeEventListener("scroll", this.func);
  },
}
</script>

<style>
.number-grow {
  letter-spacing: 2.67px;
}
</style>
